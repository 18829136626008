import React, { useState, useRef, useEffect } from "react";
import { mountRootParcel } from "single-spa";
import { CpButton, CpIcon, CpLoader, CpTooltip } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { forEach, isEmpty, get } from "lodash";
import { withRouter } from "react-router-dom";

import { useTaskAndFiles, useSofeService } from "../client-survey.helper";
import {
  addFilesToTask,
  getDocsToUpload,
} from "src/resources/tasks.resource.js";
import {
  getPrevSectionUrl,
  hasPrevious,
} from "../../source-forms/sections/section.helper.js";
import { handleValidate } from "../../source-forms/questions/required.helpers.js";

import styles from "./survey-file-upload.styles.css";

const UploadFiles = React.lazy(() =>
  SystemJS.import("docs-ui!sofe").then((m) => m.dropListChunk())
);

const UploadWrapper = (props) => {
  return (
    <React.Suspense fallback={<CpLoader />}>
      <UploadFiles uploadFilesAction={props.uploadFilesAction}>
        {props.children}
      </UploadFiles>
    </React.Suspense>
  );
};

function SurveyFileClientUpload(props) {
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [docsToUpload, setDocsToUpload] = useState([]);
  const uploadEl = useRef(null);
  const uploadAnotherEl = useRef(null);
  const docsUI = useSofeService("docs-ui!sofe");
  const [task, files, setFiles, fetching] = useTaskAndFiles(
    props.resolutionCaseId
  );
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (isEmpty(props.sourceFormLayout.sections)) return;
    setSections(props.sourceFormLayout.sections);
    const subscription = getDocsToUpload(
      props.clientId,
      props.resolutionCaseId,
      props.version.id,
      props.clientSurvey?.survey?.surveyData?.returnType
    ).subscribe((result) => {
      const relevantDocs = [];
      forEach(result, (value, key) => {
        const docName = key.replace("doc", "");
        if (key && key.startsWith("doc") && !!value) {
          relevantDocs.push({
            doc: value,
            info: get(result, `info${docName}`) || "",
          });
        }
      });
      setLoading(false);
      setDocsToUpload(relevantDocs);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.sourceFormLayout.formId]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'props.clientId', 'props.clientSurvey?.survey?.surveyData?.returnType', 'props.resolutionCaseId', 'props.sourceFormLayout.sections', and 'props.version.id'. Either include them or remove the dependency array. If 'setSections' needs the current value of 'props.sourceFormLayout.sections', you can also switch to useReducer instead of useState and read 'props.sourceFormLayout.sections' in the reducer

  const clearValue = (e) => {
    e.target.value = null;
  };

  const uploadFiles = (newFiles) => {
    docsUI
      .loadUploadFilesHelper()
      .then((filesHelper) => {
        filesHelper
          .uploadFilesAsync(newFiles, {
            destinationFolder: { id: `CON${props.clientId}` },
          })
          .then((responseFiles) => {
            if (get(task, "id")) {
              addFilesToTask(
                task.id,
                responseFiles.map((file) => file.id)
              ).subscribe(
                (result) => {
                  setUploading(false);
                  setFiles([...files, ...responseFiles]);
                },
                (err) => {
                  setUploading(false);
                }
              );
            } else {
              setUploading(false);
              setFiles([...files, ...responseFiles]);
            }
          });
      })
      .catch(handleError);
  };

  return (
    <div className={`cps-card cps-fixed-focus ${styles.subsectionWrapper}`}>
      <div className="cp-p-32">
        <div className="cps-header-sm">Upload Documents</div>
        <div className="cps-body" style={{ maxWidth: "568px" }}>
          Here are the documents we think you’ll need to upload. If there’s
          anything else your tax pro should look at, upload that here as well.
        </div>
        <div
          className="cp-mt-16"
          style={{ display: "flex", position: "relative" }}
        >
          <div
            ref={scrollContainerRef}
            style={{
              width: "339px",
              minHeight: "261px",
              maxHeight: "450px",
              overflowY: "auto",
            }}
            className="cps-well cps-subheader-sm cp-pt-24 cp-pb-24 cp-pr-24"
          >
            {fetching || loading ? (
              <div className="cps-center-vertical">
                <CpLoader />
              </div>
            ) : docsToUpload.length ? (
              <ul>
                {docsToUpload.map((doc, index) => {
                  return (
                    <li key={index}>
                      <span>{doc.doc}</span>
                      {doc.info && (
                        <CpTooltip text={doc.info}>
                          <CpIcon
                            name="information-circle-filled-small"
                            fill="var(--cp-color-app-icon)"
                          />
                        </CpTooltip>
                      )}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div
                className={`${styles.emptyWell} cps-text-center cps-center-vertical`}
              >
                <div className={`${styles.emptyText} cps-wt-bold`}>
                  We need more information to recommend files
                </div>
                <div className={`${styles.emptyText} cp-mt-8 cps-body-sm`}>
                  In order to view a list of required files, please complete
                  your personal information using the tabs on the left side of
                  this page.
                </div>
              </div>
            )}
          </div>
          {docsUI && (
            <div className="cp-ml-16" style={{ width: "339px" }}>
              {files.length === 0 ? (
                <UploadWrapper
                  config={() => docsUI.loadDropListParcel()}
                  uploadFilesAction={(files) => {
                    setUploading(true);
                    uploadFiles([...files]);
                  }}
                  mountParcel={mountRootParcel}
                >
                  <div className={`cps-well_light ${styles.uploadDocsWell}`}>
                    <div className="cps-subheader-sm cp-color-app-secondary-text cps-wt-bold">
                      Drag and Drop
                    </div>
                    <div className="cps-body">
                      <i>or</i>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <input
                        ref={uploadEl}
                        type="file"
                        name="uploadFile"
                        multiple
                        style={{ display: "none" }}
                        onClick={clearValue}
                        onChange={(e) => {
                          setUploading(true);
                          uploadFiles([...e.target.files]);
                        }}
                      />
                      <CpButton
                        onClick={() => uploadEl.current.click()}
                        btnType="secondary"
                        disabled={uploading}
                        showLoader={uploading}
                        style={{ height: "3.2rem" }}
                      >
                        Upload
                      </CpButton>
                    </div>
                  </div>
                </UploadWrapper>
              ) : (
                <>
                  <div className="cps-wt-bold cp-mb-8">Uploaded Documents</div>
                  <div style={{ maxHeight: "334px", overflowY: "auto" }}>
                    <React.Suspense fallback={<CpLoader />}>
                      <docsUI.LinkedFileListLazy
                        files={files}
                        cardWidth={"100%"}
                        cardClass={styles.fileCard}
                        isClientPortal={true}
                      />
                    </React.Suspense>
                  </div>
                  <UploadWrapper
                    config={() => docsUI.loadDropListParcel()}
                    uploadFilesAction={(files) => {
                      setUploading(true);
                      uploadFiles([...files]);
                    }}
                    mountParcel={mountRootParcel}
                  >
                    <div
                      className={`cps-well_light ${styles.uploadAnotherDocsWell}`}
                    >
                      <div className="cp-pv-24" style={{ textAlign: "center" }}>
                        <input
                          ref={uploadAnotherEl}
                          type="file"
                          name="uploadFile"
                          multiple
                          style={{ display: "none" }}
                          onClick={clearValue}
                          onChange={(e) => {
                            setUploading(true);
                            uploadFiles([...e.target.files]);
                          }}
                        />
                        <CpButton
                          btnType="flat"
                          onClick={() => uploadAnotherEl.current.click()}
                          disabled={uploading}
                          showLoader={uploading}
                        >
                          <CpIcon
                            className="cp-mr-8"
                            name="misc-cloud-up-arrow"
                          />
                          <span className="cps-color-primary">
                            Upload another
                          </span>
                        </CpButton>
                      </div>
                    </div>
                  </UploadWrapper>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`cps-card__footer`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {sections && hasPrevious(sections, null, null) && (
          <CpButton
            btnType="secondary"
            onClick={(e) =>
              handleValidate(e, () =>
                props.history.push(
                  `${props.baseUrl}${getPrevSectionUrl(sections, null, null)}`
                )
              )
            }
          >
            Previous section
          </CpButton>
        )}
        {sections && (
          <CpButton
            className="cp-ml-24"
            onClick={(e) =>
              handleValidate(e, () =>
                props.history.push(`${props.baseUrl}/submit`)
              )
            }
          >
            Next section
          </CpButton>
        )}
      </div>
    </div>
  );
}

export default withRouter(SurveyFileClientUpload);
