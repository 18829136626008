import React from "react";
import CprMask from "cpr-mask";
import { get, noop, isNumber, isNil, toString } from "lodash";
import { always } from "kremling";

import {
  toPercentString,
  divideNumberBy100,
  validateWithXsdRegex,
} from "./input.helper.js";
import { infoToast } from "toast-service!sofe";
import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";

@CanopyInput()
export default class Percent extends React.Component {
  constructor(props) {
    super(props);
    const newLocalAnswer =
      isNil(this.props.answer) || this.props.answer === ""
        ? this.props.answer
        : parseFloat(toString(this.props.answer).replace(/[,]+/g, ""));
    this.state = {
      localAnswer: isNumber(newLocalAnswer) ? newLocalAnswer : "",
      invalidMsg: "The value is not a valid percentage",
    };
    this.inputRef = React.createRef();
    this.focused = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer && !this.focused) {
      const newLocalAnswer =
        isNil(this.props.answer) || this.props.answer === ""
          ? this.props.answer
          : parseFloat(toString(this.props.answer).replace(/[,]+/g, ""));
      this.updateLocalAnswer(isNumber(newLocalAnswer) ? newLocalAnswer : "");
    }
  }

  render() {
    return (
      <CprMask
        ref={this.inputRef}
        inputClass={always(
          `cps-form-control ${styles["source-form-104"]} ${
            this.props.question.name
          } ${this.props.className || ""}`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        sideChars={{
          right: "%",
        }}
        validChars={/[0-9.]/}
        initialValue={this.state.localAnswer}
        decoder={(string) => {
          if (string === "" || string === null) return "";
          return divideNumberBy100(string);
        }}
        encoder={(value) => {
          if (typeof value === "string") return value;
          return toPercentString(value);
        }}
        onChange={this.updateLocalAnswer}
        onBlur={this.blur}
        onFocus={this.focus}
        nonValidMsgClass="cps-error-block"
        invalidClass="cps-has-error"
        validateMethod={this.validateMethod}
        nonValidMsg={this.state.invalidMsg}
        inputProps={{
          autoComplete: "off",
          style: this.props.style ? this.props.style : {},
          datax: this.props.datax,
          datay: this.props.datay,
          onPaste: this.props.onPaste || noop,
        }}
      />
    );
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  validateMethod = (value) => {
    const xsdType = get(this.props, "xsdType");

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };

  updateLocalAnswer = (value) => {
    const xsdType = get(this.props, "xsdType");
    let invalidMsg = "The value is not a valid percentage";

    this.props.validate(value);

    if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        invalidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        invalidMsg = xsdType.irsDescription;
      }
    }

    this.setState({
      localAnswer: value,
      invalidMsg,
    });
  };

  focus = () => (this.focused = true);
  blur = (answer) => {
    if (isNaN(answer)) {
      infoToast(
        `The answer for ${this.question.meta.question} was not saved the value provided was not a valid percentage.`
      );
    } else {
      this.props.validate(answer);
      this.focused = false;
      this.props.updateAnswer(this.props.question, answer);
    }
  };
}
