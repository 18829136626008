import React from "react";
import { chain, get, keysIn, isEmpty } from "lodash";
import CanopySelect from "cpr-select";
import { getOptions } from "src/source-forms/bulk-insert/tabular-insert-options.helper.js";
import PasteQuestion from "./paste-question.component.js";

const cellStyle = {
  borderBottom: "transparent",
  padding: "0.5rem",
  display: "flex",
};

export const numRows = (matrix) => {
  const matrixKeys = Object.keys(matrix);

  return matrixKeys.length
    ? parseInt(matrixKeys[matrixKeys.length - 1].match(/\d+/)[0], 10) + 1
    : 0;
};

export const buildList = (template, pasteFn, columns, updateFn) => {
  const rowProps = keysIn(template);
  return assembleInputArray(rowProps, template, [], pasteFn, columns, updateFn);
};

export const assembleInputArray = (
  objKeys,
  obj,
  carry,
  pasteFn,
  columns,
  updateFn
) => {
  if (!isEmpty(objKeys)) {
    let [head, ...tail] = objKeys;

    let tr = createTrFromTemplate(
      get(obj, head),
      head,
      pasteFn,
      columns,
      updateFn
    );

    return assembleInputArray(
      tail,
      obj,
      carry.concat(tr),
      pasteFn,
      columns,
      updateFn
    );
  } else {
    return carry;
  }
};

export const createTrFromTemplate = (
  rowArray,
  rowKey,
  pasteFn,
  columns,
  updateFn
) => {
  const rowNo = rowKey.match(/\d+/)[0];

  return (
    <div style={{ display: "flex" }} key={rowNo}>
      {createListInputs(rowArray, pasteFn, columns, updateFn)}
    </div>
  );
};

export const createListInputs = (cells, pasteFn, columns, updateFn) => {
  return cells.map((cell, i) => {
    const orderedPair = `${cell.xCoor}-${cell.yCoor}`;
    const fillValue = cell.fieldValue ? cell.fieldValue : null;
    // onchange sets the paste column state. Passes the x,y coordinate
    // tab navigation???
    return !isEmpty(columns[i]) ? (
      <div style={cellStyle} key={orderedPair}>
        <div style={{ width: 150 }}>
          {getComponent(
            columns[i].type,
            cell,
            fillValue,
            pasteFn,
            columns,
            orderedPair,
            updateFn,
            i
          )}
        </div>
      </div>
    ) : (
      false
    );
  });
};

const isListType = (type) => {
  const listTypes = [
    "COUNTRY",
    "SELECT_LIST",
    "STATE",
    "OTHER_COUNTRIES",
    "MULTIPLE_CHOICE",
    "COUNTY",
  ];

  return listTypes.includes(type);
};

const getComponent = (
  type,
  cell,
  fillValue,
  pasteFn,
  columns,
  orderedPair,
  updateFn,
  iteration
) => {
  if (isListType(type)) {
    const options =
      type === "SELECT_LIST" || type === "MULTIPLE_CHOICE"
        ? [
            {
              key: null,
              value: null,
            },
          ].concat(columns[iteration].options)
        : getOptions(type);
    const selected = fillValue && fillValue !== "BLANK" ? fillValue : 0;
    const optionsKeyMatch = options.find((option) => option.key === selected);
    const optionsValueMatch = options.find(
      (option) => option.value === selected
    );
    if (!optionsKeyMatch && optionsValueMatch) {
      updateFn(optionsValueMatch.key, cell.xCoor, cell.yCoor);
    }

    return (
      <CanopySelect
        id={orderedPair}
        datax={cell.xCoor}
        datay={cell.yCoor}
        zIndex={2}
        inputClass={`paste-input-${orderedPair}`}
        options={options}
        placeHolder={columns[iteration].label}
        selected={selected}
        onChange={(value) => {
          updateFn(value, cell.xCoor, cell.yCoor);
        }}
      />
    );
  } else {
    return (
      <PasteQuestion
        datax={cell.xCoor}
        datay={cell.yCoor}
        id={orderedPair}
        key={orderedPair}
        className={`paste-input-${orderedPair}`}
        question={columns[iteration]}
        answer={fillValue && fillValue !== "BLANK" ? fillValue : ""}
        style={{
          maxWidth: 150,
          height: "3.2rem",
          minHeight: "0px",
          minWidth: "0px",
        }}
        onPaste={pasteFn}
        onChange={(question, value) => {
          updateFn(value, cell.xCoor, cell.yCoor);
        }}
      />
    );
  }
};

export const generateHeader = (columns) => {
  const headers = columns.map((column, i) => (
    <div
      style={{
        ...cellStyle,
        minWidth: 160,
      }}
      key={`${i}-${column.label}`}
    >
      {column.label}
    </div>
  ));

  return (
    <div>
      <div style={{ display: "flex" }}>{headers}</div>
    </div>
  );
};

export const emptyRow = (columns) => Array(columns.length).fill();

export const defaultRows = (columns) => {
  const row = emptyRow(columns);
  return [row];
};

export const allEmpty = (row) => chain(row).values().filter().isEmpty().value();
