import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./survey-options.style.css";
import ClientAccess from "./client-access.component.js";
import SurveyData from "./survey-data.component.js";
import * as clientSurveyActions from "../client-survey.actions.js";
import { partial } from "lodash";

export default connect((state) => ({ clientSurvey: state.clientSurvey }))(
  SurveyOptions
);

export function SurveyOptions(props) {
  const [actions, setActions] = useState(null);
  useEffect(() => {
    setActions({
      ...bindActionCreators(clientSurveyActions, props.dispatch),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'props.dispatch'

  const { surveyOptions } = props.clientSurvey;

  const clientAccessActive = surveyOptions.selectedMenuItem === "clientAccess";
  const surveyDataActive = surveyOptions.selectedMenuItem === "surveyData";
  const isTaxPrep = surveyOptions.survey.surveyType === "taxPrep";

  return surveyOptions.isOpen && actions ? (
    <div className="cps-modal">
      <div className="cps-modal__screen" />
      <div
        className="cps-modal__dialog cps-card__height-3"
        style={{ width: isTaxPrep ? "47rem" : "55rem" }}
      >
        <div className="cps-card__header cps-subheader-sm">
          <span>Survey Options</span>
          <a
            className="cps-modal__dialog__close cps-icon cps-icon-close"
            data-testid="cancelSurveyOptions"
            onClick={actions.cancelSurveyOptions}
          />
        </div>
        <div className={`${styles.grid}`}>
          {!isTaxPrep && (
            <div className={`${styles.leftColumn} cps-card__body`}>
              <ul className={`${styles.leftMenu}`}>
                <li
                  className={`${clientAccessActive ? styles.active : ""}`}
                  onClick={partial(actions.goToSurveyOption, "clientAccess")}
                  data-testid="clientAccessAction"
                >
                  <div>Client Access</div>
                </li>
                <li
                  className={`${surveyDataActive ? styles.active : ""}`}
                  onClick={partial(actions.goToSurveyOption, "surveyData")}
                >
                  <div>Survey Data</div>
                </li>
              </ul>
            </div>
          )}
          <div className={`${styles.rightColumn}`}>
            <div className="cps-card__body">
              {surveyOptions.selectedMenuItem === "clientAccess" ||
              isTaxPrep ? (
                <ClientAccess
                  actions={actions}
                  clientSurvey={surveyOptions.survey}
                />
              ) : (
                <SurveyData
                  actions={actions}
                  resolutionCaseAnswerOrder={surveyOptions.answerOrder}
                />
              )}
            </div>
            <div
              className={`cps-modal__dialog__actions${
                isTaxPrep ? " cp-mt-24" : ""
              }`}
            >
              <button
                className={`${styles.button} cps-btn +primary`}
                onClick={actions.saveSurveyOptions}
              >
                Save
              </button>
              <a className="cps-link" onClick={actions.cancelSurveyOptions}>
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
