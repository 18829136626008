import React from "react";
import { CpButton, CpModal, constants } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";

export default function CollectionsModal(props) {
  const { showCollectionsModal, isLoading, available, close, consumeCase } =
    props;

  const hasCases = available > 0;

  const canManageAccounts = useHasAccess("company_settings_account_management");

  return (
    <CpModal show={showCollectionsModal} onClose={close} width={560}>
      <CpModal.Header
        title={hasCases ? "Use a collection case" : "No collection cases"}
      />
      {hasCases ? (
        <CpModal.Body>
          {`By downloading this document, you will use 1 of the ${available} remaining collection cases on your account.`}
        </CpModal.Body>
      ) : (
        <CpModal.Body>
          <span>You have 0 collection cases available. </span>
          {canManageAccounts ? (
            <span>
              Purchase more cases in{" "}
              <a href="/#/global-settings/account-management" target="_blank">
                Account Management
              </a>
              {` or call our Services Team at ${constants?.canopyPhone}.`}
            </span>
          ) : (
            <div>Contact your Administrator to purchase more cases.</div>
          )}
        </CpModal.Body>
      )}
      <CpModal.Footer>
        {hasCases ? (
          <>
            <CpButton onClick={consumeCase} className="cp-mr-12">
              Proceed
            </CpButton>
            <CpButton btnType="flat" onClick={close}>
              Cancel
            </CpButton>
          </>
        ) : (
          <CpButton btnType="primary" onClick={close}>
            Close
          </CpButton>
        )}
      </CpModal.Footer>
    </CpModal>
  );
}
