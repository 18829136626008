import React from "react";
import { Link, withRouter } from "react-router-dom";
import { m } from "kremling";
import { CpIcon } from "canopy-styleguide!sofe";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

import SubsectionProgress from "./cssf-subsection-progress.component.js";
import styles from "./cssf-section-progress.styles.css";
import { navigate } from "../../source-forms/sections/section.helper.js";

export function SectionProgress(props) {
  const [user] = useWithUserAndTenant();
  const complete = props.section.completed;

  return (
    <div>
      <Link
        to=""
        onClick={(e) => {
          const firstValidSubsectionIndex =
            props.section.subSections?.findIndex(
              (subSection) => subSection.visible
            );

          return navigate(
            props.surveyId,
            e,
            () =>
              props.history.push(props.getSubLink(firstValidSubsectionIndex)),
            user
          );
        }}
        className={styles.sectionProgress}
        style={{ textDecoration: "none", outline: "none" }}
      >
        <div className={styles.sectionProgressHeader}>
          {complete ? (
            <CpIcon
              name="checkmark-circle-filled-large"
              fill="var(--cp-color-app-success-text)"
            />
          ) : (
            <CpIcon
              name="shape-circle-open"
              fill="var(--cp-color-button-secondary-border)"
            />
          )}
        </div>
        <span
          className={m(
            "cp-color-app-primary",
            props.activeSection == props.section.id
          )}
        >
          {props.section.name}
        </span>
      </Link>
      <div className={styles.subsectionsList}>
        {props.expanded &&
          props.section.subSections.map((subsection, index) => {
            return (
              subsection.visible && (
                <div key={subsection.name}>
                  <SubsectionProgress
                    subLink={props.getSubLink(index)}
                    surveyId={props.surveyId}
                    subsection={subsection}
                    active={
                      props.activeSubsection == index &&
                      props.activeSection == props.section.id
                    }
                  />
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}

export default withRouter(SectionProgress);
